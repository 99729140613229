<template>
<section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20 border-t border-gray-200">

            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-20">
                <h2 class="h2">Frequently Asked Questions</h2>
            </div>

            <!-- Faqs -->
            <ul class="max-w-3xl mx-auto pl-12">
              <Accordion title="What is AutoNLP?" active>
                        AutoNLP is an NLP platform to automatically train and deploy NLP models, no code knowledge required, you can develop state-of-the-art models and integrate it into applications by APIs.
                    </Accordion>
                    
                    <Accordion title="Do I need to know about ML and NLP to use AutoNLP?" active>
                        No. AutoNLP is a interface friend, you can use it without knowledge of ML and NLP algorithms.
                    </Accordion>

                    <Accordion title="What is the public of AutoNLP?" active>
                        Developers, Business Analysts, Data Scientists, and any one that need to train and deploy NLP models fast and scalable.
                    </Accordion>

                    <Accordion title="What NLP tasks are available?">
                        Currently binary and multi-class classification tasks, soon we will launch support for more tasks.
                    </Accordion>
                    <Accordion title="What languages are available?">
                        Currently english, spanish, chinese and portuguese languages.
                    </Accordion>
                    <Accordion title="How much does it cost?">
                        Training cost is based on your training data and task, before start training we confirm with you how much it will cost.
                    </Accordion>
                    <Accordion title="Is the deployment scalable?">
                        Yes. We setup a large scale infra, depending of your demand. Currently we are able to process 100k predictions per minute.
                    </Accordion>
                    <Accordion title="Can I upload my datasets? What's supported file formats?">
                        Yes. You can upload your own datasets or public one. From CSV and JSON files, and hosted anywhere.
                    </Accordion>
                    <Accordion title="Can I download the my trained models?">
                        Yes. As your train your own models, its your, and you can download it as pytorch or tensorflow format.
                    </Accordion>
              <!--Accordion title="What is the difference between the Free and Paid versions?" active>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Accordion>
              <Accordion title="How can I change the owner of a workspace?">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Accordion-->
              <span class="block border-t border-gray-200" aria-hidden="true"></span>
            </ul>
            
        </div>
    </div>
</section>
</template>

<script>
import Accordion from './../utils/Accordion.vue'

export default {
  name: 'Faqs',
  components: {
    Accordion
  },  
}
</script>