<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <AboutIntro />
      <!--FeaturesTable /-->
      <TestimonialsCarousel />
      <Faqs />
      <Cta />       

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import AboutIntro from './../partials/AboutIntro.vue'
//import FeaturesTable from './../partials/FeaturesTable.vue'
import TestimonialsCarousel from './../partials/TestimonialsCarousel.vue'
import Faqs from './../partials/Faqs.vue'
import Cta from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Pricing',
  components: {
    Header,
    AboutIntro,
    //FeaturesTable,
    TestimonialsCarousel,
    Faqs,
    Cta,
    Footer,
  },
}
</script>
