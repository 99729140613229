import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

Vue.use(Vuex)

var CLOUD_FUNCTIONS_URL = process.env.VUE_APP_CLOUD_FUNCTIONS_URL
CLOUD_FUNCTIONS_URL = `https://us-central1-ml-saas.cloudfunctions.net`

export default new Vuex.Store({
  state: {
    modal: "",
    boards: "",
    pages: "",
    items: [],
    currentBoard: "",
    currentPage: "",
    relatedPages: "",
    tags: "",
    currentBoardItems: []
  },
  mutations: {
    setModal(state, modal) {
      state.modal = modal
    },
    ...vuexfireMutations
  },
  actions: {
    getUrlInfo(_, url) {
      return fetch(`${CLOUD_FUNCTIONS_URL}/backend/info?url=${url}`).then(response => {
        return response.json()
      })
    },
    retrieveAllPages: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.pages) {
        return
      }
      return bindFirestoreRef('pages', firebase.firestore().collection(`pages`).where("author", "!=", "doc"))
    }),
    retrieveAllTags: firestoreAction(async ({ bindFirestoreRef }) => {
      await bindFirestoreRef('tags', 
            firebase.firestore().collection(`pages`).doc("tags")
          )
    }),
    retriveRelatedPages: firestoreAction(async ({ bindFirestoreRef }, tags) => {
      if( tags != null || tags != undefined){
        await bindFirestoreRef('relatedPages', firebase.firestore().collection(`pages`).where("tags", "array-contains-any", tags).limit(5))
      }
    }),
    displayPageDetails: firestoreAction(async ({ bindFirestoreRef }, pageId) => {
      await bindFirestoreRef('currentPage', firebase.firestore().doc(`pages/${pageId}`))
      
    }),
    retrieveAllBoards: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.boards) {
        return
      }
      return bindFirestoreRef('boards', firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/boards`).orderBy('createdAt', 'desc'))
    }),
    displayBoardDetails: firestoreAction(async ({ bindFirestoreRef }, boardId) => {
      await bindFirestoreRef('currentBoard', firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`))
      await bindFirestoreRef('currentBoardItems', firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/items`)
        .where('boardId', '==', boardId)
        .orderBy('createdAt', 'desc'))
    }),
    createBoard(_, board) {
      board.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/boards`).add(board)
    },
    updateBoard(_, { boardId, update }) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`).update(update)
    },
    deleteBoard(_, boardId) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`).delete()
    },
    createItem(_, item) {
      item.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/items`).add(item)
    },
    deleteItem(_, itemId) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/items/${itemId}`).delete()
    }
  }
})
