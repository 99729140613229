<template>
  <section class="relative bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">

    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4">
            Natural language processing (NLP) applications to solve real world problems
          </h2>
          <p class="text-xl text-gray-600">
            
            These are some NLP applications that you can benefit from, allowing you to focus on the business problem, and making it easier to develop machines that write and read, to solve a real problem.
            </p>
        </div>

        <!-- Items -->
        <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

          <!-- 1st item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
              Named Entity Recognition
            </h4>
            <p class="text-gray-600 text-center">
                Identify people, locations, and organizations within a body of text.
            </p>
          </div>

          <!-- 2nd item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
              Classifiers
            </h4>
            <p class="text-gray-600 text-center">
                Classify a piece of text or a whole document.
            </p>
          </div>

          <!-- 3rd item -->
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Summarization
            </h4>
            <p class="text-gray-600 text-center">
                Summarize a longer document, identify the key information.
            </p>
          </div>


          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Relation Extraction
            </h4>
            <p class="text-gray-600 text-center">
                Go beyond the simple mentions of an entity to understand it.
            </p>
          </div>


          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Event Detection
            </h4>
            <p class="text-gray-600 text-center">
                Identify the real world events within any piece of text.
            </p>
          </div>


          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Interpretability
            </h4>
            <p class="text-gray-600 text-center">
                See what parts of the text the models are using for your predictions.
            </p>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExploreCases',
}
</script>