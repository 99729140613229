import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

Vue.config.productionTip = false

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'

import FirebasePlugin from './plugins/FirebasePlugin'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';


// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
// Vue.use(Buefy)

import 'vue-good-table/dist/vue-good-table.css'



import '@/css/style.scss';

Sentry.init({
  dsn: 'https://9418e607dada4adba467367c22ee95e9@sentry.io/1771484',
  integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  /*
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      var name = "";
      var email = ""  
      
      if (!hint) {
        hint = ""
      }

      if (firebase.auth().currentUser) {
        name = firebase.auth().currentUser.displayName
        email = firebase.auth().currentUser.email
      }
      Sentry.showReportDialog({
        eventId: event.event_id, user: {
          name, email
        }
      });
    }
    return event;
  }
  */
});

firebase.initializeApp({
  apiKey: "AIzaSyB6sPH9XEoXxZwfMfGE-fJOVKSljKPsEGk",
  authDomain: "ml-saas.firebaseapp.com",
  databaseURL: "https://ml-saas.firebaseio.com",
  projectId: "ml-saas",
  storageBucket: "ml-saas.appspot.com",
  messagingSenderId: "915060039042",
  appId: "1:915060039042:web:130852640a0f9c737a439b",
  measurementId: "G-C0MC8SNSLK"
})

firebase.analytics()


const unsubscribe = firebase.auth().onAuthStateChanged((auth) => {
  unsubscribe()

  if (auth) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: auth.email,
        username: auth.displayName,
        id: auth.uid
      })
    })
  }

  Vue.use(FirebasePlugin)

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

