<template>
  <section class="relative">

    <!-- Illustration behind hero content -->
    <div class="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none" aria-hidden="true">
      <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
            <stop stop-color="#FFF" offset="0%" />
            <stop stop-color="#EAEAEA" offset="77.402%" />
            <stop stop-color="#DFDFDF" offset="100%" />
            
          </linearGradient>
        </defs>
        
        <g fill="url(#illustration-01)" fill-rule="evenodd">
          <circle cx="1232" cy="128" r="128" />
          
          <circle cx="155" cy="343" r="64" />

        </g>
        
      </svg>

    </div>


    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Hero content -->
      <div class="pt-32 pb-8 md:pt-40 md:pb-12">

        <!-- Section header -->
        <div class="text-center pb-2 md:pb-2">
          <h1 class="text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
            Automatically train and deploy<br>
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
            world-class NLP models</span></h1>
          <div class="max-w-3xl mx-auto">
            
            <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
              AutoNLP is a no-code platform for data centric natural language processing (NLP) development. 

              Automatically train, evaluate and deploy models. Built-in tools for labeling, error analysis and monitoring.

              Explore and utilize the world’s exponentially growing sources of text-based information.

              Create a free account.

              
              
            </p>

            <!-- Categories -->
            <!--div class="mb-12 md:mb-16">
              <ul class="flex flex-wrap justify-center text-sm font-medium -m-2">
                
                <li class="m-2">
                  <a class="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">
                    Ticket routing
                  </a>
                </li>
                <li class="m-2">
                  <a class="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">
                    Answer Suggestion
                  </a>
                </li>
                <li class="m-2">
                  <a class="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">
                    Large Scale APIs
                  </a>
                </li>
                <li class="m-2">
                  <a class="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">
                    Enterprise Support
                  </a>
                </li>
              </ul>
            </div-->


            <div class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
              <div>
                <a class="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/signup">
                  Sign Up for Free
                  <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                  </svg>
                </a>
                &nbsp;&nbsp;
                
                <!--a href="https://rapidapi.com/organization/metatext" target="_blank" 
                class="btn text-white bg-gray-800 hover:bg-gray-900 w-full mb-4 sm:w-auto sm:mb-0" >
                  ModelHub
                  <svg class="w-3 h-3 fill-current text-white-200 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                  </svg>
                </a-->
              </div>
            </div>
          </div>
        </div>


        <!-- Hero image -->
        <div>
          <div class="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
            <div class="flex flex-col justify-center">
              <img class="mx-auto" 
              :src="require(`@/images/hero.svg`)"
               width="968" height="432" alt="Hero" />
              <!--svg class="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="768" height="432" viewBox="0 0 768 432" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hero-ill-a">
                    <stop stop-color="#FFF" offset="0%" />
                    <stop stop-color="#EAEAEA" offset="77.402%" />
                    <stop stop-color="#DFDFDF" offset="100%" />
                  </linearGradient>
                  <linearGradient x1="50%" y1="0%" x2="50%" y2="99.24%" id="hero-ill-b">
                    <stop stop-color="#FFF" offset="0%" />
                    <stop stop-color="#EAEAEA" offset="48.57%" />
                    <stop stop-color="#DFDFDF" stop-opacity="0" offset="100%" />
                  </linearGradient>
                  <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="hero-ill-e">
                    <stop stop-color="#4FD1C5" offset="0%" />
                    <stop stop-color="#81E6D9" offset="25.871%" />
                    <stop stop-color="#338CF5" offset="100%" />
                  </radialGradient>
                  <circle id="hero-ill-d" cx="384" cy="216" r="64" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                  <circle fill-opacity=".04" fill="url(#hero-ill-a)" cx="384" cy="216" r="128" />
                  <circle fill-opacity=".16" fill="url(#hero-ill-b)" cx="384" cy="216" r="96" />
                  <g fill-rule="nonzero">
                    <use fill="#000" xlink:href="#hero-ill-d" />
                    <use fill="url(#hero-ill-e)" xlink:href="#hero-ill-d" />
                  </g>
                </g>
              </svg-->
            </div>
            <!--button class="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg" @click.prevent.stop="videoModalOpen = true" aria-controls="modal">
              <svg class="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                <path d="M10 17l6-5-6-5z" />
              </svg>
              <svg class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                  </svg>
              <span class="ml-3">See a demo</span>
            </button-->
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
//import Modal from './../utils/Modal.vue'

export default {
  name: 'HeroHome',
  components: {
    //Modal,
  },
  data: function () {
    return {
      videoModalOpen: false,
    }
  },
}
</script>
