<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <div class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <a class="flex items-center hover:underline" href="/models">
                          <div class="flex items-center flex-grow">
                            
                            <span>Go back to search models</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden">
                    <span>Models navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"/>
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" />
                    </svg>
                  </button>

                  <NavDatasets col="language" />

                </div>
              </aside>

              <!-- Page container -->
              
          <div class="md:flex-grow">

                <div class="text-lg text-gray-600">
                  <h2 class="h2 text-gray-900 mb-4">
                    {{ content.title.replace('-',' ').replace('-', ' ') }} model
                  
                  </h2>

                  <h3 v-if="content.link" id="quick" class="h3 text-gray-900 mb-8" style="scroll-margin-top: 100px;">
                    <a :href="content.url" class="btn" target="_blank" rel=”nofollow”>
                    🤗 Huggingface
                      {{ content.link.slice(1, content.link.length) }}
                      
                    </a>
                  </h3>
                  <p class="mb-8">
                    The model {{ content.title.replace('-',' ').replace('-', ' ') }} is a Natural Language Processing (NLP) Model implemented in Transformer library, generally using the Python programming language.
                  </p>
                  <!--p class="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                    <svg class="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fill-rule="nonzero" />
                    </svg>
                    <span>
                      Create a ML Model using 
                      <a class="text-blue-600 hover:underline" 
                      id="test-leads-models"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfzMn7c2CaukYR33IlgTsc4KjYLpA9b4DOPMolsf0b-rZQttQ/viewform" 
                      target="_blank">
                        auto training and fast API deployment for NLP (Get Early Access).
                      </a>
                      
                    </span>
                  </p-->
                  <h3 class="h3 text-gray-900 mb-4">What is the {{ content.title.replace('-',' ').replace('-', ' ') }} model?</h3>
                  
                  <p class="mb-8" v-html="content.description">
                  </p>

                

                  <h4 class="h4 text-gray-900 mb-4">Model usage</h4>
                  
                  <p class="mb-8">
                    You can find {{ content.title.replace('-',' ').replace('-', ' ') }} model easily in transformers python library. To download and use any of the pretrained models on your given task, you just need to use those a few lines of codes (PyTorch version). Here an example to download using <b>pip</b> (<a class="link" href="https://packaging.python.org/tutorials/installing-packages/" target="_blank">a package installer for Python</a>)
                  </p>
                  <p class="mb-8">
                    <b>Download and install using pip</b>
                  </p>
                  <p class="mb-8">
                    <code class="prettify">
                  $ pip install transformers<br>
                  </code>
                  </p>
                  <p class="mb-8">
                    <b>Usage in python</b>
                  </p>
                  <p class="mb-8">
                    <Code :link="content.link"/>
                  </p>
                  

                  <AutoNLP />
                  <!--h4 class="h4 text-gray-900 mb-4">AutoNLP: Automatically train and deploy NLP models<small>(beta)</small></h4>
                  <p class="mb-8">
                    You can request an {{ content.title.replace('-',' ').replace('-', ' ') }} production-ready API, 
                    or deployment for your own custom model.
                    It's a new service, just fill the application form and 
                    we will provide an easy interface to deploy your model, price starting at USD 50,00/month.
                    <br>
                  </p>
                  <p class="mb-8">
                  <a href="https://forms.gle/Qi7b9ui2a7qVzE1q6" 
                    class="btn text-white bg-gray-800 hover:bg-gray-900 w-100"
                    target="_blank">
                    🚀 Request a production-ready API
                  </a>
                  </p-->
                  
                  <h4 class="h4 text-gray-900 mb-4">
                    More info
                  </h4>

                  <p class="mb-8">
                    
                    <a :href="content.url" target="_blank" rel=”nofollow”
                    class="btn text-white bg-blue-600 hover:bg-blue-700 w-100">
                      See the paper, download and more info
                    </a>
                  </p>

                  <!--p class="mb-8">
                    <span v-for="labels in content.tag.specs.split('<br>')" :key="labels">
                        
                        <p class="text-normal" v-html="labels" style="label: bold; a: bold">
                        </p>

                    </span>
                  </p-->
                  <!--p class="mb-8">
                    
                    {{
                        content.tag.labels
                    }}
                  </p-->
                </div>

                <!-- Related content -->
                <!--div class="mt-8">
                  <h3 class="h3 mb-8">Related</h3>
                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
                    <div>
                      <div class="text-normal font-medium mb-1">Folder components</div>
                      <div class="text-sm text-gray-600">Learn more about orci ac auctor augue mauris augue neque gravida.</div>
                    </div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                    </svg>
                  </a>
                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
                    <div>
                      <div class="text-normal font-medium mb-1">Deploy hooks</div>
                      <div class="text-sm text-gray-600">Learn more about risus nullam eget felis eget nunc.</div>
                    </div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                    </svg>
                  </a>
                  <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
                </div-->

                <!-- Feedback -->
                <div class="pt-6">
                  
                  <Disqus shortname='metatext' />

                  <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-6" />
                </div>


                

              </div>

            </div>

          </div>
        </div>
      </section>

    
      
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>

import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import AutoNLP from './../partials/CtaAutoNLP.vue'
import Footer from './../partials/Footer.vue'
import json_datasets from './../assets/json/models_2021-03-28.json'
import NavDatasets from './../partials/NavDatasets.vue'

import Code from "./../components/Code.vue"
import { Disqus } from 'vue-disqus'

export default {
  name: 'APIs',
		data () {
			return {
            ready: false,
            content: {}
            }
        },
        components: {
            Header,
            CtaAlternative,
            Footer,
            NavDatasets,
            Disqus,
            Code,
            AutoNLP
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
        },
        methods: {
            loadContents (path, callback) {
                

                // var p = path.split('/')[2]
                var p_str = path.split('/')[2]//.split("-").join(" ")

                // console.log(path.split('/')[-1])
                // console.log(p, p_str)

                var data = json_datasets
                
                // this.content = response.data
                this.filterName(data, p_str)
                // console.log(data[0])
                // console.log(data[data.length-1])


                //if (Array.isArray(data.body)) {
                //  this.content.body = data.body.join(" ")
                //}

                this.ready = true

                if (typeof callback === 'function') {
                    callback()
                }
            },
            filterName(data, p_str){

                // iterate over each element in the array
                for (var i = 0; i < (data.length-1); i++){
                    // look for the entry with a matching `code` value
                    if ( (String(data[i].title.replace('/', '-').toLowerCase()) == String(p_str.toLowerCase())) ){
                      
                    this.content = {
                        title: data[i].title.replace('/', ' '),
                        //tag: data[i],
                        link: data[i].link,
                        html: data[i].html,
                        description: data[i].summarization,
                        name: data[i].link,
                        url: "https://huggingface.co"+ data[i].link,
                        //pdf: "https://docs.google.com/gview?url=" + data[i].paper +"&embedded=true",
                    }

                    document.title = data[i].title + ' model - NLP Resource'

                    break;

                    // this.content = data[i]
                    }
                }
            }
        }
	}
</script>
<style scoped>

</style>