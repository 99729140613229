<template>
  <section>
    <div class="mx-auto">
      <div class="pb-8 md:pb-8">


        <!-- CTA box -->
        <div class="bg-blue-600 rounded py-8 px-8 md:py-8 md:px-8 shadow-2xl">

        <div class="flex flex-col lg:flex-row justify-between items-center">

            <!-- CTA content -->
            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
            <h3 class="h4 text-white mb-2">
                Get started using AutoNLP 🔥
                <br>
                <small>Automatically train or deploy NLP models</small>
            </h3>
            <p class="text-white text-lg opacity-75">
                AutoNLP.ai helps you saving time and money, 
                with auto training and fast deployment of NLP model into production-ready APIs.
                <b>
                    <a href="https://autonlp.ai?source=metatext" target="_blank">
                    Try it for free!
                    </a>
                </b>
            </p>
            <a href="https://autonlp.ai?source=metatext" target="_blank">
                <img src="https://autonlp.ai/img/hero.8e665010.svg" alt="AutoNLP workflow"/>
            </a>
            <a class="btn text-blue-600 bg-gradient-to-r from-blue-100 to-white " href="https://autonlp.ai?source=metatext" target="_blank">
            ➡️&nbsp;&nbsp;Get Early Access
            </a>
            </div>
        </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaAlternative',
}
</script>