<template>
  <header class="fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out" :class="{ 'bg-white blur shadow-lg': !top }">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-16 md:h-20">

        <!-- Site branding -->
        <div class="flex-shrink-0 mr-4">
          <!-- Logo -->
          <router-link to="/" class="block" aria-label="AutoNLP">
            <span class="text-3xl font-extrabold text-blue-500 subpixel-antialiased tracking-tighter 	">
              AutoNLP
              </span>
              <small class="text-xs	text-blue-500 tracking-tighter">BETA</small>
            
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:flex-grow">

          <!-- Desktop menu links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
                    
            <!-- 1st level: hover -->
            <!--Dropdown title="Integrations">
              <li>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Cloud</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Helpdesk Tools</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">CRM</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Spreadsheets</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Custom</a>
              </li>
            </Dropdown>

            <Dropdown title="Solutions">
              <li>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">NLP Suite</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Triage</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Auto-Reply</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Consultation</a>
              </li>
            </Dropdown-->

            <!--Dropdown title="Use Cases">
              <li>
                <a href="/page/ai-for-customer-support-automation" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Customer Support Automation</a>
                <a href="/page/ai-for-boost-chatbots" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Boost Chatbot Skills</a>
                <a href="/page/acceleration-program" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Consultation</a>
              </li>
            </Dropdown-->
            <li>
              <router-link to="/pricing" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Pricing</router-link>
            </li>  

            <li>
              <router-link to="/about" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              About Us</router-link>
            </li> 
            <Dropdown title="Features">
              <li>
                <a href="/#train" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Data labeling</a>
                <a href="/#train" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Auto training</a>
                <a href="/#train" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Error Analysis</a>
                <a href="/#deploy" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Deployment</a>
                <a href="/#monitor" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Monitoring</a>
              </li>
            </Dropdown>

             
            
            <!--li>
              <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
            </li-->

             <!--li>
              <a href="https://fvd1htho55.execute-api.eu-west-1.amazonaws.com/v1/doc" target="_blank" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">API</a>
            </li-->
            
            <!--li>
              <router-link to="/datasets" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">NLP Datasets</router-link>
            </li-->  

            <!--li>
              <router-link to="/models" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Models</router-link>
            </li-->  

            <!--li>
              <router-link to="/page/open-ai-gpt-3-portuguese" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              GPT-3 | Open AI Partner
                
              </router-link>
            </li--> 

            <!--li>
              <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
            </li-->

            <!--li>
                  <a href="https://e7uqx26jzwq.typeform.com/to/vCE0W6hR" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact Us</a>
            </li-->   

          </ul>

          <!-- Desktop sign in links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <a href="https://app.autonlp.ai/login" target="_blank" class="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Sign in</a>
            </li>
            <li>
              <!--router-link to="/signup" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"-->
              <a href="/signup" class="btn-sm text-white bg-blue-600 hover:bg-blue-700 ml-3">
                <span>
                  Sign Up for Free
                </span>
                <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                </svg>
              <!--/router-link-->
              </a>
            </li>
          </ul>

        </nav>

        <!-- Mobile menu -->
        <div class="flex md:hidden">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >          
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
            >
              <ul class="px-5 py-2">
                
                <li>
                  <a href="/#train" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Auto Training
                  </a>
                </li>

                <li>
                  <a href="/#deploy" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Fast Deployment
                  </a>
                </li>

                <li>
                  <a href="/#monitor" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Monitoring
                  </a>
                </li>

                <li>
              <a href="/pricing" 
              class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Pricing
              </a>
            </li>

            <li>
              <a href="/about" 
              class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              About Us
              </a>
            </li>
                
                <!--li>
                  <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
                </li-->

                <li>
                  <a href="https://fvd1htho55.execute-api.eu-west-1.amazonaws.com/v1/doc" target="_blank" class="flex text-gray-600 hover:text-gray-900 py-2">API</a>
                </li>
                
                <li>
                  <a href="https://app.autonlp.ai/login" class="flex text-gray-600 hover:text-gray-900 py-2">Sign in</a>
                </li>   
                <li>
                  <!--router-link to="/signup" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"-->
                  <a href="/signup" target="_blank" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                    <span>Sign up</span>
                    <svg class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                    </svg>
                  <!--/router-link-->
                  </a>
                </li>
                <li>
                  <a href="https://app.autonlp.ai/login" class="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center">Sign in</a>
                </li>
                <!--li>
                  <router-link to="/signup" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                    <span>Sign up</span>
                    <svg class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill="#999" fill-rule="nonzero" />
                    </svg>                
                  </router-link>
                </li-->
              </ul>
            </nav>
          </transition>

        </div>

      </div>
    </div>
  </header>
</template>

<script>
import Dropdown from './../utils/Dropdown.vue'

export default {
  name: 'Header',
  components: {
    Dropdown
  },
  data: function () {
    return {
      mobileNavOpen: false,
      top: true
    }
  },
  methods: {
    clickOutside(e) {
      if (!this.mobileNavOpen || this.$refs.mobileNav.contains(e.target) || this.$refs.hamburger.contains(e.target)) return
      this.mobileNavOpen = false
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return
      this.mobileNavOpen = false
    },
    handleScroll() {
      this.top = window.pageYOffset > 10 ? false : true
    }
  },  
  mounted() {
    document.addEventListener('click', this.clickOutside)    
    document.addEventListener('keydown', this.keyPress)
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('keydown', this.keyPress)
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>