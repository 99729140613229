<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="max-w-3xl mx-auto lg:max-w-none">
          
          <article>

            <!-- Article header -->
            <header class="max-w-3xl mx-auto mb-20">
              <!-- Title -->
              <h1 class="h1 text-center mb-4">{{ page.title }}</h1>
              <!--center>
                <span class="text-center"
                v-for="tag in page.tags"
                :key="tag">
                #{{ tag }}&nbsp;
                </span>
              </center-->
            </header>
            
            <!-- Article content -->
            <div class="lg:flex lg:justify-between" data-sticky-container>

                
              <!-- Sidebar -->
              <!--aside class="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                  <h4 class="text-lg font-bold leading-snug tracking-tight mb-4">Table of contents</h4>
                  <ul class="font-medium -my-1" 
                  v-for="section in page.sections"
                  :key="section">
                    <li class="py-1">
                      <a class="flex items-center hover:underline" 
                      :href="'#'+section.trim()">
                        <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                        </svg>
                        <span>{{ section.trim() | capitalize }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </aside-->

              <!-- Main content -->
              <div>

                <!-- Article meta -->
                <div class="flex items-center mb-6" v-if="page.author !== 'doc'">
                  <!--div class="flex flex-shrink-0 mr-3">
                    <a class="relative" href="#0">
                      <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                      <img class="relative rounded-full" :src="require('@/images/news-author-04.jpg')" width="32" height="32" alt="Author 04" />
                    </a>
                  </div-->
                  <div >
                    <span class="text-gray-600">Published by </span>
                    <a class="font-medium hover:underline" href="#0">
                      {{ page.author }}
                    </a>
                    <span class="text-gray-600">&nbsp;·&nbsp; Last Updated at
                      {{ page.date }}
                    </span>
                  </div>
                  
                </div>
                
                
                <!-- Article body -->
                <div class="text-lg text-gray-600" v-html="page.content">
                  
                </div>

              </div>

            </div>

            <!-- Article footer -->
          </article>

          <!-- Related content -->
          <div class="mt-8">
            <h3 class="h3 mb-8">Related contents</h3>
            
            <span v-for="related in relatedPages"
                  :key="related.title">
            <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" 
            :href="related.id">
              <div>
                <div class="text-normal font-medium mb-1">{{ related.title }}</div>
                <!--div class="text-sm text-gray-600" v-html="related.content.substring(0, 100)+'...'">
                </div-->
              </div>
              <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
              </svg>
            </a>
            </span>
            <!--hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" /-->
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PostSingle',
  data () {
			return {
            path: "",
            // page: { title: 'title', content: 'content'}
        }
  },
  computed: {
    page() {
      return this.$store.state.currentPage || { title: 'loading title', content: 'loading content'}
    },
    relatedPages() {
      return this.$store.state.relatedPages
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  beforeRouteLeave (to, from, next) {
      if (to.meta.componentName === 'Page') {
      this.loadContents(to.path, next)
      } else {
      next()
      }
  },
  beforeRouteUpdate (to, from, next) {
      this.loadContents(to.path, next)
  },
  created(){
      this.loadContents(this.$route.path, this.loadRelatedPages)
      
  },
  methods: {
      loadRelatedPages() {
        

        setTimeout(() => {  
          this.$store.dispatch("retriveRelatedPages", this.page.tags);
         }, 2000);

        
      },  
      loadContents(path, callback) {
          
          var p = path.split('/')
          this.path = p[p.length-1]
          String.prototype.capitalize = function() {
              return this.charAt(0).toUpperCase() + this.slice(1);
          }
          document.title = this.path.replace(/[^\w\s]/gi, ' ').capitalize() + " - AutoNLP"

          this.$store.dispatch("displayPageDetails", this.path);

          if (typeof callback === 'function') {
              callback()
          }
      }
  }

}
</script>