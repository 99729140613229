<template>
    <section class="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-16">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <!--h4 class="h4">HOW IT WORKS</h4-->
          <h2 class="h2">
            Start with game-changing artificial intelligence (AI) development
          </h2>
          <br>

            <p class="text-xl text-gray-600">
              AutoNLP is a new way to create powerfull AI models, 
              with tools that help you keep high quality data and 
              production-ready natural language processing (NLP) models. 
               <br><br>

               <br>
               AI development made simple.
            </p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Process',
}
</script>