<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 class="h1 mb-4">NLP Models Repository</h1>
          <h2 class="h4 mb-4">
            List of Natural Language Processing Models to use in your AI projects
          </h2>
          <p class="text-xl text-gray-600">
            Transformers models covering tasks from Summarization, Text Classification, to Text Generation. 
            We hope you find this library useful in your development endeavors
          </p>
          
        </div>
           
        <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    styleClass="vgt-table "

                    :search-options="{
                        enabled: true,
                        trigger: 'enter',
                        skipDiacritics: true,
                        placeholder: 'What are you looking for? i.e., type a source, task or language <Enter>',
                        
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        position: 'top',
                        perPageDropdown: [10, 25, 50, 100],
                        dropdownAllowAll: true,
                        setCurrentPage: 1,
                        nextLabel: 'next',
                        prevLabel: 'prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                    }"                    
                    >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'title'">
                        <a :href="'/models/' + props.row.title.replace('/', '-').toLowerCase()" class="hover:underline">
                            {{props.formattedRow[props.column.field]}}
                        </a>
                    </span>
                    <span v-else>
                        <a :href="'/models/' + props.row.title.replace('/', '-').toLowerCase()" class="hover:underline">
                            {{props.formattedRow[props.column.field]}}
                        </a>
                    </span>

                </template>
        </vue-good-table>

        <br><br>
        <AutoNLP />

        <!-- Feedback -->
        <div class="pt-6">
            <div class="flex flex-col text-center sm:text-left sm:flex-row sm:justify-between sm:items-center">
            <div class="font-medium mb-4 md:mb-0">Was this page helpful? Share to help more people.</div>

            
            <twitter-button
                class="share-button--outline" 
            />
            <facebook-button
                class="share-button--outline" 
                
            />
            <whatsApp-button
                class="share-button--outline" 
                
            />
            <telegram-button
                class="share-button--outline" 
                
            />
            <linkedIn-button
                class="share-button--outline" 
                
            />
            </div>
            <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-6" />
        </div>

      </div>
    </div>
  </section>
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import { VueGoodTable } from 'vue-good-table';
import json_datasets from './../assets/json/models_2021-03-28.json'

import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import TelegramButton from "vue-share-buttons/src/components/TelegramButton";
import LinkedInButton from "vue-share-buttons/src/components/LinkedInButton";
import AutoNLP from './../partials/CtaAutoNLP.vue'

export default {
  name: 'Models',
		data () {
			return {
            // options: config.options,
            text: 'Text example here',
            result: 'Your result here',
            btn: "Start Analyzer",
            processing: false,
            total_rows: 902,
            
            columns: [
                {
                    label: 'Model Name',
                    field: 'title',
                },
                {
                    label: 'Description',
                    field: 'summarization',
                },
                {
                    label: 'Task',
                    field: 'Category',
                    filterOptions: {
                        enabled: false, // enable filter for this column
                        placeholder: 'Filter This Thing', // placeholder for filter input
                        //filterValue: '', // initial populated value for this filter
                        filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        filterFn: this.columnFilterFn, //custom filter function that
                        trigger: 'enter', //only trigger on enter not on keyup 
                    },
                }
            ]
			}
        },
        components: {
            Header,
            CtaAlternative,
            Footer,
            VueGoodTable,
            TwitterButton,
            FacebookButton,
            WhatsAppButton,
            TelegramButton,
            LinkedInButton,
            AutoNLP
        },
        created(){
            this.process()
        },
        methods: {
            process () {
                this.btn = "Analyzing, wait some seconds..."
                this.result = "Loading..."

                this.processing = true

                // axios
                // .get(, params, headers)

                // console.log(url)
                this.rows = json_datasets
            },
        }
	}
</script>
