import Vue from 'vue'
import Router from 'vue-router'

import HomeLandingPage from './pages/Home.vue'
import Tutorials from './pages/Tutorials.vue'
import Pricing from './pages/Pricing.vue'
import Blog from './pages/Blog.vue'
import BlogPost from './pages/BlogPost.vue'
// import PagePost from './pages/PagePost.vue'
import About from './pages/About.vue'
import Documentation from './pages/Documentation.vue'
import Support from './pages/Support.vue'
import PageNotFound from './pages/PageNotFound.vue'

import SignUp from './pages/SignUp.vue'
import SignIn from './pages/SignIn.vue'

import Products from './pages/Products.vue'

import Datasets from './pages/Datasets.vue'
import DatasetsPage from './pages/DatasetsPage.vue'
import DatasetsList from './pages/DatasetsList.vue'
import Redirect from './pages/Redirect.vue'

import APIs from './pages/APIs.vue'
import APIsPage from './pages/APIsPage.vue'
import APIsList from './pages/APIsList.vue'

import Models from './pages/Models.vue'
import ModelsPage from './pages/ModelsPage.vue'
import ModelsList from './pages/ModelsList.vue'

import firebase from 'firebase/app'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: HomeLandingPage,
    },
    {
      path: '/artificial_intelligence',
      component: HomeLandingPage,

    },
    {
      path: '/tutorials',
      component: Tutorials
    },
    {
      path: '/api/:id',
      component: Products
    },
    {
      path: '/pricing',
      component: Pricing,
      meta: {
        title: 'Pricing'
      }
    },
    {
      path: '/blog',
      component: Blog,
      meta: {
        title: 'NLP Blog'
      }
    },
    {
      path: '/blog/:id',
      component: BlogPost
    },
    {
      path: '/page/:id',
      component: BlogPost
    },
    {
      path: '/about',
      component: About,
      meta: {
        title: 'About Page'
      }
    },
    {
      path: '/documentation',
      component: Documentation
    },
    {
      path: '/support',
      component: Support
    },    
    {
      path: '/datasets/:page',
      component: DatasetsPage
    },   
    {
      path: '/datasets',
      component: Datasets,
      meta: {
        title: 'Curated NLP Database - Repository of 1000+ NLP Datasets',
        description: 'Fing 1000+ NLP Datasets. Covering tasks from classification to question answering, languages from English, Portuguese to Arabic.'
      },
    },   
    {
      path: '/datasets-list/:page',
      component: DatasetsList      
    },        
    {
      path: '/redirect/:page',
      component: Redirect
    },  
    {
      path: '/apis/:page',
      component: APIsPage,
    },   
    {
      path: '/apis',
      component: APIs,
      meta: {
        title: 'Best APIs for Natural Language Processing in 2021 - NLP Resource',
        description: 'Fing 200+ NLP APIs. Free, custom and proprietary APIs. Covering tasks from drug discovery to sentiment analysis, languages from Portuguese, English to Chinese.'
      }
    },   
    {
      path: '/apis-list/:page',
      component: APIsList      
    },  
      
    {
      path: '/models',
      
      component: Models,
      meta: {
        title: 'Models for Natural Language Processing - NLP Resource',
        description: '+6000 NLP models covering tasks from Summarization, Text Classification, to Text Generation. We hope you find this library useful in your development endeavors.'
      }
    },   
    {
      path: '/models/:page',
      component: ModelsPage,
      meta: {
        title: 'Models for Natural Language Processing - NLP Resource',
        description: '+6000 NLP models covering tasks from Summarization, Text Classification, to Text Generation. We hope you find this library useful in your development endeavors.'
      }
    }, 
    {
      path: '/models-list/:page',
      component: ModelsList      
    },
    {
      path: '/signup',
      component: SignUp,
      meta: {
        title: 'Invite'
      }   
    },
    {
      path: '/invite',
      component: SignUp,
      meta: {
        title: 'Invite'
      }     
    }, 
    {
      path: '/signin',
      //component: SignIn,
      redirect: 'https://app.autonlp.ai/login',
      meta: {
        title: 'Sign in'
      }    
    }, 
    {
      path: '*',
      component: PageNotFound,
      meta: {
        title: 'Page not found'
      }
    }

  ]
})

// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {
  if (firebase.auth().currentUser && to.fullPath == "/") {
    next("/app")
  }
  else if (to.matched.some(it => it.meta.requiresAuth)) {
    if (firebase.auth().currentUser) {
      next()
    }
    else {
      next("/login")
    }
  }
  else if (to.matched.some(it => it.meta.authPage) && firebase.auth().currentUser) {
    next("/app")
  }
  else {
    next()
  }

  const suffix_title = " - AutoNLP"

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (document.title !== "AutoNLP") {
    document.title = document.title + suffix_title
  }
  

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
})

export default router