<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />
      <FeaturesList />
      <Features />
      <!--<UseCases />-->

      

      
      
      <FeaturesBlocks />

      <Pricing />
      
      
      <!--Faqs /-->
      <Cta />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>

import Header from './../partials/Header.vue'
import HeroHome from './../partials/HeroHome.vue'
//import FeaturesHome from './../partials/FeaturesHome.vue'
import FeaturesBlocks from './../partials/ExploreCases.vue'
import Pricing from './../partials/TestimonialsCarousel.vue'
import Features from './../partials/Features2.vue'
import FeaturesList from './../partials/FeaturesList.vue'

//import HowWorks from './../partials/HowWorks.vue' // adapt to how it works
// import Stats from './../partials/Team.vue' // credentials and depoiments
// import Stats from './../partials/TestimonialsCarousel.vue' // credentials, news from Gartner etc
//import FeaturesWorld from './../partials/FeaturesWorld.vue'
//import News from './../partials/News.vue'
//import Faqs from './../partials/Faqs.vue'
//import UseCases from './../partials/UseCases.vue'

//import PricingTables from './../partials/PricingTables.vue'

import Cta from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeroHome,
    //FeaturesHome,
    FeaturesBlocks,
    Pricing,
    Features,
    //UseCases,
    FeaturesList,
    //HowWorks,
    //PricingTables,
    //Faqs,
    Cta,
    Footer,
  },
  created(){
      document.title = "AutoNLP"
  },
}
</script>
