<template>
  <footer>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Top area: Blocks -->
      <div class="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

        <!-- 1st block -->
        <div class="sm:col-span-12 lg:col-span-3">
          <div class="mb-2">
            <!-- Logo -->
            <router-link to="/" class="inline-block" aria-label="Cruip">
              <span class="text-3xl font-extrabold text-blue-500 subpixel-antialiased tracking-tighter 	">
              AutoNLP
              </span>
              <small class="text-xs	text-blue-500 tracking-tighter">BETA</small>
            
            </router-link>
          </div>
          <div class="text-sm text-gray-600">
            <a href="/page/terms-of-service" class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">
              Terms
            </a> · 
            <a href="/page/privacy-policy"  class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">
              Privacy
            </a> · 
            <router-link to="/about" class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">
              About Us
            </router-link>
          </div>
        </div>

        <!-- 2nd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Our services</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <a href="https://fvd1htho55.execute-api.eu-west-1.amazonaws.com/v1/doc" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">API Documentation</a>
            </li>

            <li class="mb-2">
              <a href="https://status.metatext.io/" target="_blank" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">API Status Page</a>
            </li>
          </ul>
        </div>

        <!-- 3rd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Datasets</h6>
          <ul class="text-sm">
            
            <li class="mb-2">
              <a href="https://metatext.io/datasets#" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Curated NLP Datasets</a>
            </li>
            <li class="mb-2">
              <a href="https://metatext.io/datasets-list/portuguese-language" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Portuguese Datasets</a>
            </li>
            <li class="mb-2">
              <a href="https://metatext.io/datasets-list/text-classification-task" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Text Classification Datasets</a>
            </li>
          </ul>
        </div>

        <!-- 4th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-800 font-medium mb-2">Resources</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <a href="https://metatext.io/apis" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Best APIs for NLP</a>
            </li>
            <li class="mb-2">
              <a href="https://metatext.io/models" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">State-of-the-art Models for NLP</a>
            </li>
          </ul>
        </div>

        <!-- 5th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-800 font-medium mb-2">Application</h6>
          
          
          <ul class="text-sm">

            <li class="mb-2">
                 <a href="mailto:hi@autonlp.ai" 
                 class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">
                    Enterprise Support
                  </a>
               
            </li>


            <li class="mb-2">
                 <a href="/signup" 
                 class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">
                    Get Early Access
                  </a>
               
            </li>

            <li class="mb-2">
                 <a href="https:/app.autonlp.ai/login" 
                 class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">
                    Sign in
                  </a>
               
            </li>
          </ul>
                        
               
        </div>

      </div>

      <!-- Bottom area -->
      <div class="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

        <!-- Social links -->
        <!--ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <router-link to="#" class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
              </svg>
            </router-link>
          </li>
          <li class="ml-4">
            <router-link to="#" class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
              </svg>
            </router-link>
          </li>
          <li class="ml-4">
            <router-link to="#" class="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Linkedin">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </router-link>
          </li>
        </ul-->

        <!-- Copyrights note -->
        <div class="text-sm text-gray-600 mr-4">&copy; 2021 AutoNLP 🇧🇷. All rights reserved.</div>

      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>